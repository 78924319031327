@import './base';


.showBlog__contenido__portada {
    width: 100%;
    height: 45rem;
    @media screen and (max-width: 576px) {
        height: auto;
        margin: 0rem 0rem !important;
    }
    &-img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: 50% 35%;
        //position: absolute;
        top: 0;
        left: 0;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
}

//Article
.showBlog__main{
    z-index: 4;
    font-family: 'Poppins';
    font-size: 1rem;
}

.showBlog__article__post {
    background-color: #fff;
    position: relative;
    padding: 0rem 6rem;
    font-size: 1.5rem;

    @media screen and (max-width: 576px) {
        padding: 0rem 3rem;
    }
    &-item {
        margin-bottom: 2rem;
        word-break: break-word;
        @media screen and (max-width: 576px) {
            text-align: justify !important;
        }
    }
}

.content-width {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
}

.article__img {
    display: flex;
    flex-direction: column;
    & > span {
        font-size: 1.2rem;
        color: rgb(117,117,117);
    }
}

.showBlog__share{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12rem 0;

    &-title{
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 2rem;
        align-self: center;
        text-align: center;
    }
    &__items{
        & > button {
            margin: 0rem 2rem;
            opacity: .8;
            @media screen and (max-width: 576px) {
                margin: 2px 10px;
            }

            &:hover{
                opacity: 1;
            }
        }
    }
}