.carouselContainer {
    display: flex;
    flex-direction: column;

    align-items: center;

    &__main{
        margin: 2rem 0;   
    }

    &__item{
        display: flex;
        justify-content: space-between;
        
        margin: 3rem 0;
        & > span {
            font-size: 2rem;
            
        }

        &-delete{
            font-size: 16px !important;
        }
    }

    &__carga{
        width: 100%;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-top: 2px;
        border-style: solid;
        padding-top: 2rem;

        display: flex;
        justify-content: center;
    }
}