@import './colors';
//Huawei P20 Lite 360*760
.root2 {
    height: 100vh;
    scroll-snap-type: y mandatory;
    // -moz-scroll-snap-type: y mandatory;
    // -ms-scroll-snap-type: y mandatory;
    overflow-y: scroll;
    //overflow: hidden;
}

/*
* Index 0 en _carouselIndex.scss
*/

.index__slide__1{
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    width: 100%;
    &-box{
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        
        @media screen and (max-width: 576px) {
            flex-direction: column;
            justify-content: center;
        }
    }

    &__figure{
        flex: 0 0 45%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 2rem;
        
        /* Portrait iPad Pro 12.9"*/
        @media only screen 
        and (min-device-width: 1024px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            flex: 0 0 50%;
        }
        /* Portrait iPad Pro 10.5"*/
        @media only screen 
        and (min-device-width: 834px) 
        and (max-device-width: 834px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            flex: 0 0 50%;
        }
        /* Portrait iPad*/
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 768px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            flex: 0 0 50%;
        }
        @media screen and (max-width: 576px) {
            flex: 1;
            padding: 1rem 3rem;
        }
        &-img{
            object-fit: cover;
            max-width: 100%;
            height: auto;
            padding-right: 10%;
            /* Portrait iPad Pro 12.9"*/
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                padding-right: 2rem;
            }
            /* Portrait iPad Pro 10.5"*/
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                padding-right: 2rem;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                padding-right: 2rem;
                margin-top: 20%;
            }
            @media screen and (max-width: 576px) {
                margin: 0;
                padding-top: 25%;
                font-size: 4rem;
                line-height: 4rem;
            }
            @media screen and (max-width: 576px) {
                padding: 0;
                height: 100%;
            }
        }
    }

    &__textleft {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        align-self: stretch;
        padding-left: 10%;
        /* Portrait iPad Pro 12.9"*/
        @media only screen 
        and (min-device-width: 1024px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 0 2rem;
            padding-left: 1rem;
            text-align: center;
        }
        /* Portrait iPad Pro 10.5"*/
        @media only screen 
        and (min-device-width: 834px) 
        and (max-device-width: 834px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 0 2rem;
            text-align: center;
        }
        /* Portrait iPad*/
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 768px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            padding: 0 2rem;
            text-align: center;
        }
        @media screen and (max-width: 576px) {
            padding: 0;
            flex: 0 0 50%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        //iPhone 5
        @media screen and (max-width: 320px){
            padding: 0;
            flex: 0 0 55%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        
        &-up{
            display: flex;
            flex-direction: column;
            margin-top: 35rem;
            font-size: 8rem;
            line-height: 8rem;
            /* Portrait iPad Pro 12.9"*/
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                font-size: 8rem;
                line-height: 8rem;
            }
            /* Portrait iPad Pro 10.5"*/
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                font-size: 5.5rem;
                line-height: 6rem;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                font-size: 5.5rem;
                line-height: 6rem;
                margin-top: 20rem;
            }
            @media screen and (max-width: 576px) {
                margin: 0;
                padding-top: 25%;
                font-size: 4rem;
                line-height: 4rem;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                margin: 0;
                padding-top: 35%;
                font-size: 4rem;
                line-height: 4rem;
            }
        }

        &-mid{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            font-size: 3rem;
            line-height: 3.2rem;
            /* Portrait iPad Pro 12.9"*/
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                font-size: 4rem;
                line-height: 4.5rem;
            }
            /* Portrait iPad Pro 10.5"*/
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                font-size: 3rem;
                line-height: 3rem;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                font-size: 2.5rem;
                line-height: 3rem;
            }
            @media screen and (max-width: 576px) {
                margin: 0;
                padding-top: 25%;
                font-size: 4rem;
                line-height: 4rem;
            }
            @media screen and (max-width: 576px) {
                padding: 5% 0;
                font-size: 1.8rem;
                line-height: 2rem;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                padding: 4% 0;
                font-size: 1.7rem;
                line-height: 2rem;
            }
        }

        &-down{
            margin-bottom: 5rem;
            font-size: 2rem;
            /* Portrait iPad Pro 12.9"*/
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                
            }
            /* Portrait iPad Pro 10.5"*/
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                margin-bottom: 50%;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                
            }
            @media screen and (max-width: 576px) {
                margin: 0;
                font-size: 2rem;
            }
            &-link:link,
            &-link:visited {
                text-decoration: none;
                color: $color_text_dark;
                &:hover{
                    color: $color_text_green;
                    text-decoration: underline;
                }
            }
        }
    }
}

.index__slide__2{
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    width: 100%;
    &-box{
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        @media screen and (max-width: 576px) {
            flex-direction: column;
            justify-content: center;
        }
    }
    &__figure{
        flex: 1;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 576px) {
            flex: 1;
        }
        &-img{
            flex: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            //Objectposition para centrar a la chica
            object-position: 96% 50%;
            /* Portrait iPad Pro 12.9"*/
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                object-position: 68% 50%;
            }
            /* Portrait iPad Pro 10.5"*/
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                object-position: 50% 50%;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                object-position: 70% 50%;
            }
            @media screen and (max-width: 576px) {
                object-position: 100% 50%;
            }
        }
        &-img-2{
            flex: 1;
            height: 100%;
            width: 100%;
            object-fit: cover;
            //Centrar a la chica y la niña
            object-position: 54% 50%;
            /* Portrait iPad Pro 12.9"*/
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                object-position: 68% 50%;
            }
            /* Portrait iPad Pro 10.5"*/
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                object-position: 68% 50%;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                object-position: 67% 50%;
            }
            @media screen and (max-width: 576px) {
                object-position: 50% 50%;
            }
        }
        &-img-3{
            flex: 1;
            height: 100%;
            width: 100%;
            object-fit: cover;
            //Centrar a la chica y la niña
            object-position: 54% 50%;
            /* Portrait iPad Pro 12.9"*/
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                object-position: 4% 50%;
            }
            /* Portrait iPad Pro 10.5"*/
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                object-position: 5% 50%;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                object-position: 5% 50%;
            }
            @media screen and (max-width: 576px) {
                object-position: 50% 50%;
            }
        }
    }

    
    &__textleft {
        flex: 0 0 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //flex: 1;
        align-self: stretch;
        padding-left: 10%;
        /* Portrait iPad Pro 12.9"*/
        @media only screen 
        and (min-device-width: 1024px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 0 2rem;
            text-align: center;
        }
        /* Portrait iPad Pro 10.5"*/
        @media only screen 
        and (min-device-width: 834px) 
        and (max-device-width: 834px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 0 2rem;
            text-align: center;
        }
        /* Portrait iPad*/
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 768px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            padding: 0 2rem;
            text-align: center;
        }
        @media screen and (max-width: 576px) {
            padding: 0;
            flex: 0 0 50%;
            //justify-content: flex-end;
            justify-content: center;
            text-align: center;
        }
        &-up{
            display: flex;
            flex-direction: column;
            font-size: 6.4rem;
            line-height: 6.4rem;
            margin-top: 35rem;

            &-mobile {
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                }
            }

            &-desktop-item{
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            @media screen and (max-width: 576px) {
                margin: 0;
                padding-top: 15%;
                font-size: 4rem;
                line-height: 4rem;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                margin: 0;
                padding-top: 25%;
                font-size: 4rem;
                line-height: 4rem;
            }
        }

        &-mid{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            font-size: 2.66rem;
            line-height: 3.2rem;
            &-mobile {
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                }
            }
            &-desktop-item{
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }

            
            @media screen and (max-width: 576px) {
                font-size: 2rem;
                line-height: 2rem;
                padding: 5% 0;
            }
        }

        &-down{
            margin-bottom: 5rem;
            font-size: 2rem;
            @media screen and (max-width: 576px) {
                margin: 0;
                font-size: 2rem;
            }

            &-link-1:link,
            &-link-1:visited {
                text-decoration: none;
                color: $color_text_dark;
                &:hover{
                    color: $color_text_green;
                    text-decoration: underline;
                }
            }
            &-link-2:link,
            &-link-2:visited {
                text-decoration: none;
                color: $color_text_dark;
                &:hover{
                    color: lighten($color_text_blue, 5%);
                    text-decoration: underline;
                }
            }
            &-link-3:link,
            &-link-3:visited {
                text-decoration: none;
                color: $color_text_dark;
                &:hover{
                    color: lighten($color_text_green, 5%);
                    text-decoration: underline;
                }
            }
        }
    }
}

.index__slide__5{
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media screen and (max-width: 576px) {
        justify-content: center;
        display: none;
    }

    &__top{
        margin-right: 20%;
        @media screen and (max-width: 576px) {
            margin: 0;
        }
        &-box{
            background-color: #BFBFBF;
            color: #fff;
            padding-top: 2rem;
            padding-bottom: 2rem;
            font-size: 6.4rem;
            line-height: 6.4rem;
            text-align: center;
            @media screen and (max-width: 576px) {
                padding: 0;
                font-size: 3rem;
                text-align: center;
            }
        }
    }

    &__down{
        display: flex;
        justify-content: space-evenly;
        
        @media screen and (max-width: 576px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 0 0 90%;
        }
    }

    &__card{
        display: flex;
        position: relative;
        /* Portrait iPad*/
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            flex-direction: column;
        }
        @media screen and (max-width: 576px) {
            flex-direction: column;
        }
        &__container{
            height: 48rem;
            width: 35rem;
            margin: 0;
            padding: 0;
            position: relative;
            @media screen and (max-width: 1200px) {
                height: 45rem;
                width: 30rem;
            }
            @media screen and (max-width: 992px) {
                height: 40rem;
                width: 25rem;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                height: 40rem;
                width: 25rem;
            }
            @media screen and (max-width: 576px) {
                height: 24rem;
                width: 17.5rem;
            }
        }
        &-body-green{
            //Overlay
            position: absolute;
            bottom: 0;
            background: #00BE00B3;
            color: white;
            width: 100%;
            padding: 1rem;

            display: flex;
            flex-direction: column;

            font-size: 1.86rem;

            &-desktop{
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &-mobile{
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                    font-size: 1rem;
                }
            }
        }

        &-body-blue{
            position: absolute;
            bottom: 0;
            background: #007ABAB3;
            color: white;
            width: 100%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            font-size: 1.86rem;
            &-desktop{
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &-mobile{
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                    font-size: 1rem;
                }
            }
        }

        &-body-black{
            position: absolute;
            bottom: 0;
            background: #1f1f1fB3;
            color: white;
            width: 100%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            font-size: 1.86rem;
            &-desktop{
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }
            &-mobile{
                display: none;
                @media screen and (max-width: 576px) {
                    display: block;
                    font-size: 1rem;
                }
            }
        }

        &-img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            cursor: pointer;
            opacity: .85;

            transition: opacity .2s;
            &:hover{
                opacity: 1;
            }
        }

        &-title-1{
            position: absolute;
            bottom: 6rem;
            left: -14rem;
            transform: rotate(270deg);
            font-size: 5.2rem;
            @media screen and (max-width: 1200px) {
                font-size: 4.5rem;
                line-height: 4.5rem;
                left: -38%;
                bottom: 14%;
            }
            @media screen and (max-width: 992px) {
                font-size: 4rem;
                line-height: 4rem;
                left: -42%;
                bottom: 14%;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                position: unset;
                transform: rotate(0deg);
                text-align: center;
                font-size: 4rem;
                line-height: 6rem;
            }
            @media screen and (max-width: 576px) {
                position: unset;
                transform: rotate(0deg);
                font-size: 2rem;
                text-align: center;
            }
            &-link:link,
            &-link:visited{
                text-decoration: none;
                color: inherit;

                &:hover {
                    text-decoration: underline;
                    color: darken($color_text_green, 5%);
                }
            }
        }
        &-title-2{
            position: absolute;
            bottom: 5rem;
            left: -13rem;
            transform: rotate(270deg);
            font-size: 5.2rem;
            @media screen and (max-width: 1200px) {
                font-size: 4.5rem;
                line-height: 4.5rem;
                left: -36%;
                bottom: 14%;
            }
            @media screen and (max-width: 992px) {
                font-size: 4rem;
                line-height: 4rem;
                left: -38%;
                bottom: 14%;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                position: unset;
                transform: rotate(0deg);
                text-align: center;
                font-size: 4rem;
                line-height: 6rem;
            }
            @media screen and (max-width: 576px) {
                position: unset;
                transform: rotate(0deg);
                font-size: 2rem;
                text-align: center;
            }
            &-link:link,
            &-link:visited{
                text-decoration: none;
                color: inherit;

                &:hover {
                    text-decoration: underline;
                    color: darken($color_text_blue, 5%);
                }
            }
        }
        &-title-3{
            position: absolute;
            bottom: 12rem;
            left: -19rem;
            transform: rotate(270deg);
            font-size: 5.2rem;
            @media screen and (max-width: 1200px) {
                font-size: 4.5rem;
                line-height: 4.5rem;
                left: -55%;
            }
            @media screen and (max-width: 992px) {
                font-size: 4rem;
                line-height: 4rem;
                left: -58%;
            }
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                position: unset;
                transform: rotate(0deg);
                text-align: center;
                font-size: 4rem;
                line-height: 6rem;
            }
            /* Landscape iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: landscape) 
            and (-webkit-min-device-pixel-ratio: 1) {

            }
            @media screen and (max-width: 576px) {
                position: unset;
                transform: rotate(0deg);
                font-size: 2rem;
                text-align: center;
            }
            &-link:link,
            &-link:visited{
                text-decoration: none;
                color: inherit;

                &:hover {
                    text-decoration: underline;
                    color: lighten($color_text_dark, 5%);
                }
            }
        }
    }
}

.index__slide__8{
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    width: 100%;
}

.index__slide__9{
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &-img {
        height: 100%;
        padding: 10rem 0;
        & > img {
            height: 100%;
            width: 100%;
        }
        /* Portrait iPad Pro 12.9"*/
        @media only screen 
        and (min-device-width: 1024px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 35rem 0;
        }
        /* Portrait iPad Pro 10.5"*/
        @media only screen 
        and (min-device-width: 834px) 
        and (max-device-width: 834px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            padding: 25rem 0;
        }
        /* Portrait iPad*/
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 768px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            display: none;
            padding: 25rem 0;
        }
        //Pixel 2
        @media screen and (max-width: 414px){
            display: none;
        }
    }

    &__textright {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Portrait iPad Pro 12.9"*/
        @media only screen 
        and (min-device-width: 1024px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            text-align: center;
            padding-left: 2rem;
        }
        /* Portrait iPad Pro 10.5"*/
        @media only screen 
        and (min-device-width: 834px) 
        and (max-device-width: 834px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            text-align: center;
        }
        /* Portrait iPad*/
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 768px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            text-align: center;
        }
        //iPhone 6/7/8 plus
        @media screen and (max-width: 414px){
            align-items: center;
            justify-content: space-evenly;
        }

        &-top{
            display: flex;
            flex-direction: column;
            font-size: 4.66rem;
            line-height: 5.6rem;
            &-desktop {
                @media screen and (max-width: 480px){
                    display: none;
                }
            }

            &-mobile{
                display: none;
                font-size: 4.66rem;
                line-height: 5.6rem;
                text-align: center;
                //iPhone 6/7/8 plus
                @media screen 
                and (max-width: 414px)
                and (device-height: 736px){
                    font-size: 4rem;
                    line-height: 4rem;
                }
                //Pixel 2
                @media screen and (max-width: 411px) {
                    font-size: 4rem;
                    line-height: 4rem;
                }
                //iPhone 6/7/8
                @media screen 
                and (max-width: 375px)
                and (height: 667px){
                    
                }
                //iPhone X
                @media screen and (max-width: 375px){
                    
                }
                //Moto G4/Galaxy S5
                @media screen and (max-width: 360px) {
                    font-size: 4rem;
                    line-height: 4rem;
                }
                //iPhone 5
                @media screen and (max-width: 320px){
                    font-size: 3.5rem;
                    line-height: 3.5rem;
                }
                @media screen and (max-width: 480px){
                    display: flex;
                }
            }
        }

        &-mid{
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            font-size: 4rem;
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                margin: 25% 0;
            }
            @media screen and (max-width: 480px){
                display: flex;
                margin: 0;
            }
            &-desktop{
                @media screen and (max-width: 480px){
                    display: none;
                }
            }
            &-mobile{
                display: none;
                text-align: center;
                @media screen and (max-width: 480px){
                    display: flex;
                }
                //iPhone 6/7/8 plus
                @media screen 
                and (max-width: 414px)
                and (device-height: 736px){
                    font-size: 2.5rem;
                }
                //Pixel 2
                @media screen and (max-width: 411px) {
                    font-size: 2.5rem;
                }
                //iPhone 6/7/8
                @media screen 
                and (max-width: 375px)
                and (height: 667px){
                    
                }
                //iPhone X
                @media screen and (max-width: 375px){
                    
                }
                //Moto G4/Galaxy S5
                @media screen and (max-width: 360px) {
                    font-size: 2.5rem;
                }
                //iPhone 5
                @media screen and (max-width: 320px){
                    font-size: 2.5rem;
                }
                
                
            }
        }

        &-down{
            display: flex;
            justify-content: space-between;
            margin-top: 10rem;
            /* Portrait iPad*/
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                margin: 0;
            }
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                margin: 0;
            }
            //Pixel 2
            @media screen and (max-width: 411px) {
                margin: 0;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                margin: 0;
            }
            //Moto G4/Galaxy S5
            @media screen and (max-width: 360px) {
                margin: 0;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                margin: 0;
            }
            @media screen and (max-width: 480px){
                display: flex;
            }
        }
    }
}

.index__slide__5-mobile{
    scroll-snap-align: center;
    scroll-snap-stop: always;
    display: none;
    height: 100vh;
    width: 100%;
    @media screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__titulo{
        font-size: 3rem;
        text-align: center;
        background: #BFBFBF;
        color: #fff;
        align-self: stretch;
        padding: 2rem 0;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        //iPhone 6/7/8 plus
        @media screen 
        and (max-width: 414px)
        and (device-height: 736px){
            padding: 0;
            padding-bottom: 1rem;
            align-items: flex-end;
        }
        //Pixel 2
        @media screen and (max-width: 411px) {
            padding: 0;
            padding-bottom: 1.5rem;
            align-items: flex-end;
        }
        //iPhone X
        @media screen and (max-width: 375px){
            padding: 0;
            padding-bottom: 1rem;
            align-items: flex-end;
        }
        //Moto G4/Galaxy S5
        @media screen and (max-width: 360px) {
            padding: 0;
            padding-bottom: 1rem;
            align-items: flex-end;
        }
        //iPhone 5
        @media screen and (max-width: 320px){
            padding: 0;
            padding-bottom: 1rem;
            align-items: flex-end;
        }
    }

    &__card{
        display: flex;
        flex-direction: column;
        flex: 0 0 70%;
        align-items: center;
        //iPhone 6/7/8 plus
        @media screen 
        and (max-width: 414px)
        and (device-height: 736px){
            flex: 0 0 82%;
        }
        //Pixel 2
        @media screen and (max-width: 411px) {
            flex: 0 0 80%;
        }
        //iPhone 6/7/8
        @media screen 
        and (max-width: 375px)
        and (height: 667px){
            flex: 0 0 74% !important;
        }
        //iPhone X
        @media screen and (max-width: 375px){
            flex: 0 0 78%;
        }
        //Moto G4 / Galaxy S5
        @media screen and (max-width: 360px) {
            flex: 0 0 72%;
        }
        //iPhone 5
        @media screen and (max-width: 320px){
            flex: 0 0 70%;
        }
        &__container{
            height: 48rem;
            width: 35rem;
            margin: 0;
            padding: 0;
            position: relative;
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                height: 52rem;
                width: 37rem;
            }
            //Pixel 2
            @media screen and (max-width: 411px){
                height: 50rem;
                width: 35rem;
            }
            //iPhone 6/7/8
            @media screen 
            and (max-width: 375px)
            and (height: 667px){
                height: 42rem !important;
                width: 33rem !important;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                height: 55rem;
                width: 35rem;
            }
            //MotoG4/GalaxyS5
            @media screen and (max-width: 360px){
                height: 39rem;
                width: 31rem;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                height: 33rem;
                width: 28rem;
            }
        }

        &-img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .85;

            transition: opacity .2s;
            &:hover{
                opacity: 1;
            }
        }

        &-title-1{
            font-size: 3rem;
            text-align: center;
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                font-size: 4rem;
                padding: 1rem 0;
            }

            //Pixel 2
            @media screen and (max-width: 411px){
                font-size: 4rem;
                padding: 1rem 0;
            }
            //MotoG4/GalaxyS5
            @media screen and (max-width: 360px){
                font-size: 3rem;
                padding: 1rem 0;
            }


            &-link:link,
            &-link:visited{
                text-decoration: none;
                color: inherit;

                &:hover{
                    text-decoration: underline;
                    color: darken($color_text_green, 5%);
                }
            }
        }

        &-title-2{
            font-size: 3rem ;
            text-align: center;
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                font-size: 4rem;
                padding: 1rem 0;
            }
            //Pixel 2
            @media screen and (max-width: 411px){
                font-size: 4rem;
                padding: 1rem 0;
            }
            //Moto G4 / Galaxy S5
            @media screen and (max-width: 360px){
                font-size: 3rem ;
                padding: 1rem 0;
            }
            &-link:link,
            &-link:visited{
                text-decoration: none;
                color: inherit;

                &:hover {
                    text-decoration: underline;
                    color: darken($color_text_blue, 5%);
                }
            }
        }

        &-title-3{
            font-size: 3rem ;
            text-align: center;
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                font-size: 4rem;
                padding: 1rem 0;
            }
            //Pixel 2
            @media screen and (max-width: 411px){
                font-size: 4rem;
                padding: 1rem 0;
            }
            //Moto G4 / Galaxy S5
            @media screen and (max-width: 360px){
                font-size: 3rem ;
                padding: 1rem 0;
            }
            &-link:link,
            &-link:visited{
                text-decoration: none;
                color: inherit;

                &:hover {
                    text-decoration: underline;
                    color: lighten($color_text_dark, 5%);
                }
            }
        }

        &-body-green{
            position: absolute;
            bottom: 0;
            background: #00BE00B3;
            color: white;
            width: 100%;
            font-size: 1.86rem;
            padding: 1rem;
            display: flex;
            flex-direction: column;
        }

        &-body-blue{
            position: absolute;
            bottom: 0;
            background: #007ABAB3;
            color: white;
            width: 100%;
            font-size: 1.86rem;
            padding: 1rem;
            display: flex;
            flex-direction: column;
        }

        &-body-black{
            position: absolute;
            bottom: 0;
            background: #1f1f1fB3;
            color: white;
            width: 100%;
            font-size: 1.86rem;
            padding: 1rem;
            display: flex;
            flex-direction: column;
        }
    }

}

.index__icons {
    &-item{
        $size: 7rem;
        height: $size;
        width: $size;
        fill: $color_text_green;
        /* Portrait iPad Pro 12.9"*/
        @media only screen 
        and (min-device-width: 1024px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            width: 6rem;
            height: 6rem;
            margin: 0 2rem;
        }
        /* Portrait iPad Pro 10.5"*/
        @media only screen 
        and (min-device-width: 834px) 
        and (max-device-width: 834px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 2) {
            width: 6rem;
            height: 6rem;
            margin: 0 2rem;
        }
        /* Portrait iPad*/
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 768px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            width: 6rem;
            height: 6rem;
            margin: 0 2rem;
        }
        //iPhone 6/7/8 plus
        @media screen 
        and (max-width: 414px)
        and (device-height: 736px){
            height: 5rem;
            width: 5rem;
            margin: 0 2rem;
        }
        //Pixel 2
        @media screen and (max-width: 411px) {
            height: 4rem;
            width: 4rem;
            margin: 0 2rem;
        }
        //iPhone 6/7/8
        @media screen 
        and (max-width: 375px)
        and (height: 667px){
            height: 4rem;
            width: 4rem;
            margin: 0 2rem;
        }
        //iPhone X
        @media screen and (max-width: 375px){
            
        }
        //Moto G4/Galaxy S5
        @media screen and (max-width: 360px) {
            height: 4rem;
            width: 4rem;
            margin: 0 2rem;
        }
        //iPhone 5
        @media screen and (max-width: 320px){
            
        }
        @media screen and (max-width: 480px){
            display: flex;
        }
        & > path {
            //transition: fill .4s ease;
        }

        &-twitter {
            &:hover{
                fill: #1DA1F2;
            }
        }
        &-tiktok {
            &:hover {
                fill: #000000;
            }
        }
        &-youtube {
            &:hover {
                fill: #FF0000;
            }
        }
        &-facebook:hover {
            fill: #3B5998;
        }
        &-instagram {
            & path {
                fill: $color_text_green;
            }
            &:hover path{
                fill: url(#rg);
            }
        }
    }
}

path {
    transition: fill .4s ease;
}

