@import "./base";
.image-container {
    min-height: 30rem;  //Permite que en el media query se adapte bien
    display: flex;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    &-first {
        height: 100%;
        flex: 0 0 60%;
        border: 1px solid $color-gray-dark-1;
        position: relative;
    }

    &-second {
        height: 100%;
        flex: 1;
        border: 1px solid $color-gray-dark-1;
        position: relative;
        
        @media screen and (max-width: 768px) {
            margin-top: 1rem;
        }
    }

    &__info {
        position: absolute;
        bottom: 0;
        background: rgb(0,0,0);
        background: rgba(0,0,0,0.5);
        color: #fff;
        width: 100%;
        
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-tags {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            align-self: stretch;
            flex-wrap: wrap;

            &-item{
                padding: 0 2rem;
                background-color: $color-primary-blue;
                border-radius: 10px;
                cursor: pointer;
                color: #fff;
                margin-top: .5rem;
                transition: all .2s;
                
                &:not(:last-child) {
                    margin-right: 2rem;
                }

                &:first-child {
                    margin-left: auto;
                }

                &:last-child {
                    margin-right: auto;
                }

                &:hover {
                    background-color: lighten($color-primary-blue, 5%);
                    border-radius: 10px;
                }
            }
        }

        &-title {
            font-family: "Poppins",sans-serif;
            font-size: 1.8rem;
            margin: 1rem auto;
            cursor: pointer;
        }

        &__details {
            font-family: "Poppins", sans-serif;
            margin: 0 auto;
        }

        &-author {
            font-size: 1rem;
            & > span {
                cursor: pointer;
            }
        }

        &-date {
            font-size: .6rem;
        }

        &-link:link,
        &-link:visited {
            text-decoration: none;
            color: #fff;
            text-align: center;
            align-self: center;

            &:hover {
                color: darken(#fff,15%);
            }
        }
        
    }
}

.imageCard {
    height: 30rem;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    opacity: .85;
    transition: all .2s;
    &:hover {
        opacity: 1;
    }
}