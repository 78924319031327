.footer-index{
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 50%;

    &-up{
        align-self: flex-end;
        margin-right: 10rem;
        flex: 0 0 50%;
        padding: 5rem 0;
        @media screen and (max-width: 992px) {
            margin-right: 5%;
        }
        &:first-of-type div {
            margin-bottom: 2rem;
        }
    }
    
    &-down{
        background: #000;
        color: #00BE00;
        flex: 1;
        padding: 5rem 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: 992px) {
            display: flex;
            flex-direction: column;
        }
    }

    &__menu__left {
        display: flex;
        @media screen and (max-width: 992px) {
            width: 100%;
            justify-content: space-evenly;
        }
        &-1{
            display: flex;
            flex-direction: column;
            margin-right: 10rem;
            @media screen and (max-width: 992px) {
                margin: 0;
            }
            &-title{
                margin-bottom: 3rem;
                @media screen and (max-width: 992px) {
                    margin-bottom: 2rem;
                }
            }
            &-list{
                display: flex;
                flex-direction: column;
                & > span {
                    margin-bottom: 2rem;
                    @media screen and (max-width: 992px) {
                        margin: 0;
                    }
                }
            }
        }

        &-2{
            display: flex;
            flex-direction: column;
            margin-right: 10rem;
            @media screen and (max-width: 992px) {
                margin: 0;
            }
            &-title{
                margin-bottom: 3rem;
                @media screen and (max-width: 992px) {
                    margin-bottom: 2rem;
                }
            }
            &-list{
                display: flex;
                flex-direction: column;
                & > span {
                    margin-bottom: 2rem;
                    @media screen and (max-width: 992px) {
                        margin: 0;
                    }
                }
            }
        }
    }

    &__menu__right{
        margin-right: 5rem;
        //iPhone 6/7/8 plus
        @media screen 
        and (max-width: 414px)
        and (device-height: 736px){
            margin: 0;
        }
        //Pixel 2
        @media screen and (max-width: 411px) {
            margin: 0;
        }
        //iPhone 6/7/8
        @media screen 
        and (max-width: 375px)
        and (height: 667px){
            margin: 0;
        }
        //iPhone X
        @media screen and (max-width: 375px){
            margin: 0;
        }
        //Moto G4/Galaxy S5
        @media screen and (max-width: 360px) {
            margin: 0;
        }
        //iPhone 5
        @media screen and (max-width: 320px){
            margin: 0;
        }
        &-1, &-3{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-bottom: 2rem;
            flex-wrap: wrap;
            @media screen and (max-width: 992px) {
                flex-direction: row;
            }
        }

        &-4{
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                font-size: 2rem !important;
                text-align: center;
            }
            //Pixel 2
            @media screen and (max-width: 411px) {
                font-size: 2rem !important;
                text-align: center;
            }
            //iPhone 6/7/8
            @media screen 
            and (max-width: 375px)
            and (height: 667px){
                font-size: 2rem !important;
                text-align: center;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                font-size: 2rem !important;
                text-align: center;
            }
            //Moto G4/Galaxy S5
            @media screen and (max-width: 360px) {
                font-size: 2rem !important;
                text-align: center;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                font-size: 2rem !important;
                text-align: center;
            }
        }
    }

    &__menu__icons{
        $size: 5rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-around;
        &-item{
            &-icon{
                height: $size;
                width: $size;
                fill: #00BE00;
                & > path {
                    transition: fill .4s ease;
                }

                &-twitter {
                    &:hover{
                        fill: #1DA1F2;
                    }
                }
                &-tiktok {
                    &:hover{
                        fill: #bfbfbf;
                    }
                }
                &-youtube {
                    &:hover{
                        fill: #F00;
                    }
                }
                &-instagram {
                    &:hover{
                        fill: url(#rg-footer);
                    }
                }
                &-facebook:hover {
                    fill: #3B5998;
                }
            }
        }
    }
}

.footer{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 10rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    &-up{
        align-self: flex-end;
        margin-right: 10rem;
        flex: 0 0 50%;
        padding: 5rem 0;
        @media screen and (max-width: 992px) {
            margin-right: 5%;
        }
        //iPhone 6/7/8 plus
        @media screen 
        and (max-width: 414px)
        and (device-height: 736px){
            margin: 0;
            text-align: center;
        }
        //Pixel 2
        @media screen and (max-width: 411px) {
            margin: 0;
            text-align: center;
        }
        //iPhone 6/7/8
        @media screen 
        and (max-width: 375px)
        and (height: 667px){
            margin: 0;
            text-align: center;
        }
        //iPhone X
        @media screen and (max-width: 375px){
            margin: 0;
            text-align: center;
        }
        //Moto G4/Galaxy S5
        @media screen and (max-width: 360px) {
            margin: 0;
            text-align: center;
        }
        //iPhone 5
        @media screen and (max-width: 320px){
            margin: 0;
            text-align: center;
        }
        &:first-of-type div {
            margin-bottom: 2rem;
        }
    }
    
    &-down{
        background: #000;
        color: #00BE00;
        flex: 1;
        padding: 5rem 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: 992px) {
            display: flex;
            flex-direction: column;
        }
    }

    &__menu__left {
        display: flex;
        @media screen and (max-width: 992px) {
            width: 100%;
            justify-content: space-evenly;
        }
        &-1{
            display: flex;
            flex-direction: column;
            margin-right: 10rem;
            @media screen and (max-width: 992px) {
                margin: 0;
            }
            &-title{
                margin-bottom: 3rem;
                @media screen and (max-width: 992px) {
                    margin-bottom: 2rem;
                }
            }
            &-list{
                display: flex;
                flex-direction: column;
                & > span {
                    margin-bottom: 2rem;
                    @media screen and (max-width: 992px) {
                        margin: 0;
                    }
                }
            }
        }

        &-2{
            display: flex;
            flex-direction: column;
            margin-right: 10rem;
            @media screen and (max-width: 992px) {
                margin: 0;
            }
            &-title{
                margin-bottom: 3rem;
                @media screen and (max-width: 992px) {
                    margin-bottom: 2rem;
                }
            }
            &-list{
                display: flex;
                flex-direction: column;
                & > span {
                    margin-bottom: 2rem;
                    @media screen and (max-width: 992px) {
                        margin: 0;
                    }
                }
            }
        }
    }

    &__menu__right{
        margin-right: 5rem;
        //iPhone 6/7/8 plus
        @media screen 
        and (max-width: 414px)
        and (device-height: 736px){
            margin: 0;
        }
        //Pixel 2
        @media screen and (max-width: 411px) {
            margin: 0;
        }
        //iPhone 6/7/8
        @media screen 
        and (max-width: 375px)
        and (height: 667px){
            margin: 0;
        }
        //iPhone X
        @media screen and (max-width: 375px){
            margin: 0;
        }
        //Moto G4/Galaxy S5
        @media screen and (max-width: 360px) {
            margin: 0;
        }
        //iPhone 5
        @media screen and (max-width: 320px){
            margin: 0;
        }
        &-1, &-3{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-bottom: 2rem;
            flex-wrap: wrap;
            @media screen and (max-width: 992px) {
                flex-direction: row;
            }
        }
        &-4{
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                font-size: 2rem !important;
                text-align: center;
            }
            //Pixel 2
            @media screen and (max-width: 411px) {
                font-size: 2rem !important;
                text-align: center;
            }
            //iPhone 6/7/8
            @media screen 
            and (max-width: 375px)
            and (height: 667px){
                font-size: 2rem !important;
                text-align: center;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                font-size: 2rem !important;
                text-align: center;
            }
            //Moto G4/Galaxy S5
            @media screen and (max-width: 360px) {
                font-size: 2rem !important;
                text-align: center;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                font-size: 2rem !important;
                text-align: center;
            }
        }
    }

    &__menu__icons{
        $size: 5rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-around;
        &-item{
            &-icon{
                height: $size;
                width: $size;
                fill: #00BE00;
                & > path {
                    transition: fill .4s ease;
                }

                &-twitter {
                    &:hover{
                        fill: #1DA1F2;
                    }
                }
                &-tiktok {
                    &:hover{
                        fill: #bfbfbf;
                    }
                }
                &-youtube {
                    &:hover{
                        fill: #F00;
                    }
                }
                &-instagram {
                    &:hover{
                        fill: url(#rg-footer);
                    }
                }
                &-facebook:hover {
                    fill: #3B5998;
                }
            }
        }
    }
}

.gato-logo-index{
    position: absolute;
    left: 5%;
    top: 10%;
    width: 18%;
    height: auto;
    @media screen and (max-width: 1200px) {
        
    }
    /* Portrait iPad Pro 12.9"*/
    @media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        
    }
    /* Portrait iPad Pro 10.5"*/
    @media only screen 
    and (min-device-width: 834px) 
    and (max-device-width: 834px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        left: 60%;
        top: 18%;
    }
    /* Portrait iPad*/
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 768px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
        left: 60%;
        top: 18%;
    }
    //iPhone 6/7/8 plus
    @media screen 
    and (max-width: 414px)
    and (device-height: 736px){
        top: 30%;
        left: 35%;
        width: 25%;
    }
    //Pixel 2
    @media screen and (max-width: 411px) {
        top: 30%;
        left: 35%;
        width: 25%;
    }
    //iPhone 6/7/8
    @media screen 
    and (max-width: 375px)
    and (height: 667px){
        top: 32%;
        left: 35%;
        width: 25%;
    }
    //iPhone X
    @media screen and (max-width: 375px){
        top: 32%;
        left: 35%;
        width: 25%;
    }
    //Moto G4/Galaxy S5
    @media screen and (max-width: 360px) {
        top: 32%;
        left: 35%;
        width: 25%;
    }
    //iPhone 5
    @media screen and (max-width: 320px){
        top: 35%;
        left: 35%;
        width: 25%;
    }
}

.gato-logo{
    position: absolute;
    left: 5%;
    top: 10%;
    width: 18%;
    height: auto;
    @media screen and (max-width: 1200px) {
        
    }
    /* Portrait iPad Pro 12.9"*/
    @media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        left: 3%;
        top: 25%;
    }
    /* Portrait iPad Pro 10.5"*/
    @media only screen 
    and (min-device-width: 834px) 
    and (max-device-width: 834px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        left: 60%;
        top: 25%;
    }
    /* Portrait iPad*/
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 768px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
        left: 60%;
        top: 28%;
    }
    //iPhone 6/7/8 plus
    @media screen 
    and (max-width: 414px)
    and (device-height: 736px){
        top: 37%;
        left: 35%;
        width: 25%;
    }
    //Pixel 2
    @media screen and (max-width: 411px) {
        top: 38%;
        left: 35%;
        width: 25%;
    }
    //iPhone X
    @media screen and (max-width: 375px){
        top: 39%;
        left: 34%;
        width: 25%;
    }
    //iPhone 6/7/8
    @media screen 
    and (max-width: 375px)
    and (height: 667px){
        top: 39%;
        left: 35%;
        width: 25%;
    }
    //Moto G4/Galaxy S5
    @media screen and (max-width: 360px) {
        top: 39%;
        left: 36%;
        width: 25%;
    }
    //iPhone 5
    @media screen and (max-width: 320px){
        top: 43%;
        left: 37%;
        width: 25%;
    }
}

.footer__legal{
    font-size: 1.3rem;
    font-family: 'Lexend';
    font-weight: 300;
    text-align: center;
    border-top: solid 2px white;
    color: #00BE00;
    background-color: #000;
    &:hover{
        .footer__legal__name {
            display: none;
        }
        .footer__legal__contact{
            display: block;
        }
    }
    &__contact{
        display: none;
    }
}