@import './base';

.showBlog__header {
    min-height: 15rem;
    margin-top: -15rem;
    background-color: #fff;
    position: relative;
    //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 -23px 25px, rgba(0, 0, 0, 0.08) 0 -2px 2px 0;
    padding: 3rem 6rem;
    display: flex;
    flex-direction: column;

    &__title{
        font-family: 'Lexend';
        font-weight: 700;
        font-size: 3.5rem;
        margin-top: 2rem;
    }

    &__autor {
        margin-top: 2rem;
        display: flex;
        //flex-direction: column;
        //justify-content: space-around;
        justify-content: space-evenly;
        &-item{
            display: flex;
            align-items: center;
        }
        &-img{
            width: 5rem;
            height: auto;
            border-radius: 100px;
            border: 1px solid black;
        }
        &__info{
            &-name{
                margin-left: 2rem;
                font-size: 1.5rem;
                font-weight: 400;
                
                display: flex;
                flex-direction: column;

                //align-items: center;
                & > :last-child(){
                    gap: 1rem;
                }
            }
            &-redes{
                align-self: center;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &__autorUnico {
        margin-top: 2rem;
    }

    &__tags{
        display: flex;
        align-items: center;

        &-item {
            border-radius: 100px;
            background-color: $color-primary-green;
            
            padding: .5rem 1.5rem;
            color: #fff;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1px;
            cursor: pointer;
            &:not(:first-child){
                margin-left: 2rem;
            }
            &:hover{
                background-color: lighten($color-primary-green, 5%);
            }
        }
    }

    &__fecha{
        font-size: 1.5rem;
        color: rgb(117,117,117);

        margin-top: 2rem;
    }

    &__por{
        font-size: 1.5rem;

    }
}

.showBlogAuthorIcon{
    height: 2.25rem;
    width: 2.25rem;

    &-fa{
        fill:#3B5998;
    }
    &-ig{
        fill: pink;
    }
    &-tw{
        fill:#1DA1F2;
    }

    &-link{
        margin: 0 1rem;
    }
}


@media only screen and (max-width: 576px){

    .showBlog__header__autor__info-redes{
        justify-content: center;
    }

    .showBlogAuthorIcon{
        &-link{
            margin: 5px 5px;
        }
    }

    .showBlog__header{
        padding: 3rem 3rem;
        margin: 0;

        /* Portrait iPad*/
        &__autor{
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2rem;
        }
    }
}