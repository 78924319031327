 @import './base';
///////////////////////////////////////////////////////
// LOGO

.logo {
    height: 3.25rem;
    margin-left: 2rem;
    @media screen and (max-width: 206px) {
        height: 2rem;
        margin: 0;
    }
}

///////////////////////////////////////////////////////
// USER NAVIGATION

.user-nav {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    flex: 0 0 40%;
    margin: 0 auto;
    
    @media screen and (max-width: 1200px) {
        flex: 0 0 auto;

    }
    
    @media screen and (max-width: 992px) and (min-width: 768px) {
        //order: -1;
        flex: 0 0 100%;
        justify-content: space-evenly;
        padding: 1rem 0;
    }

    //Navbar collapse
    //Problema con 769px
    @media screen and (max-width: 767px) {
        display: block;
        padding-top: 2rem;
        padding-bottom: 0;
        flex-direction: column;
    }

    & > * {
        padding: 0 2rem;
        cursor: pointer;    //cambia el cursor por una de seleccion
        height: 100%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1200px) {
            //padding: 1rem;
        }

        @media screen and (max-width: 768px) {
            justify-content: center;
        }

        &:hover {
            background-color: $color-gray-light-2;
        }
    }

    &__link:link,
    &__link:visited {
        color: $color-primary-green;
        text-decoration: none;
        // text-transform: uppercase; no quiso letra mayus
        font-weight: 600;
    }
}

///////////////////////////////////////////////////////
// USER ICONS

.user__icons {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-evenly;

    //Temporal FIX!!
    //flex-wrap: wrap;

    
    @media screen and (min-width: 992px) {
        margin-right: 2rem;
    }
    @media screen and (max-width: 768px) {
        justify-content: space-evenly;
        padding: 2rem;
    }

    @media screen and (max-width: 992px) {
        flex: 1;
    }

    &-item {
        //padding: 0 2rem;
        margin-left: 1rem;
        @media screen and (min-width: 992px) {
            //padding: 0 1rem;
        }
        &-icon {
            height: 2.25rem;
            width: 2.25rem;
            fill: $color-primary-green;
    
            &-twitter:hover {
                fill: #1DA1F2;
            }
            &-tiktok:hover {
                fill: $color-primary-black;
            }
            &-instagram:hover {
                fill: url(#rg);
            }
            &-youtube:hover {
                fill: red;
            }
            &-facebook:hover {
                fill: #3B5998;
            }
        }
    }
}

///////////////////////////////////////////////////////
// SEARCH
.search {
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    
    &__form {
        @media screen and (max-width: 768px) {
            display: flex;
            flex: 0 0 40%;
            justify-content: center;
        }
    }

    &__input {
        //width: inherit;
        background-color: darken($color-gray-light-3, 7%);
        border: none;
        padding: .7rem 2rem;
        border-radius: 100px;
        width: 90%;
        margin-right: -3.25rem;
        transition: all .2s;

        &:focus {
            outline: none;
            width: 100%;
            background-color: darken($color-gray-light-3,4%);
        }
    }

    &__input:focus + &__button {
        background-color: darken($color-gray-light-3,4%);
    }

    &__button {
        border: none;
        background-color: darken($color-gray-light-3, 7%);
        padding: 0;
        transition: all .2s;

        &:focus {
            outline: none;
        }

        &:active {
            transform: translateY(2px);
        }
    }
}

.header__content{
    height: 100%;
}