@import './colors';

.contactoWrapper{
    display: flex;
    justify-content: center;
    height: clamp(30rem, 60%, 120rem);
    height: fit-content;
    width: 100%;

    
    padding-top: 10rem;
    padding-bottom: 40rem;

    background-image: url('../components/Pages/img/contactoBackground.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.contactoErrorMsg {
    padding: 2rem;
    font-size: clamp(1.2rem, 50%, 2rem);
    font-family: 'Lexend';
    font-weight: 600;
    color: hsl(0, 65%, 46%);
    background-color: lightcoral;
    margin: 2rem 0;
}

.contactoForm {
    //align-self: center;
    border: 1px solid black;
    height: auto;
    width: 70%;
    text-align: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    background-color: #fff;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border: 5px solid $color_text_blue;
}

.contactoTitle {
    color: $color_text_green;
    margin-top: 5%;
    margin-bottom: 5%;
    & > h1 {
        font-size: clamp(4.5rem, 70%, 12rem);
        //font-size: 3rem;
    }
    & > h2 {
        font-size: 3rem;
    }
}

.contactoBox {
    width: 70%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    margin-bottom: 3rem;
    font-size: 1.3rem;
    & > div {
        display: flex;
    }

    & > div > span {
        flex: 1;
        align-self: center;
        text-align: left;
        color: darken($color_text_green, 5%);
    }

    & > div > input,
    & > div > textarea {
        flex: 70%;
        font-size: 'Poppins';
    }

    & > div > textarea {
        min-height: 10rem;
    }
}

.btn__contacto {
    color: #fff;
    background-color: $color_text_green !important;
    border-color: $color_text_green !important;
    margin-bottom: 5%;

    &:hover{
        background-color: lighten($color_text_green, 4%) !important;
    }

    &>span{
        font-size: 1.2vw;
    }
}

@media screen and (max-width: 1400px) {
    .contactoForm{
        width: 80%;
    }
}
@media screen and (max-width: 992px) {
    .contactoForm{
        width: 90%;
    }
    .btn__contacto{
        &>span{
            font-size: 2vw;
        }
    }
}
@media screen and (max-width: 768px) {
    .contactoForm{
        width: 90%;
    }
    .btn__contacto{
        &>span{
            font-size: 2.5vw;
        }
    }
}
@media screen and (max-width: 576px) {
    .contactoForm{
        width: 100%;
        height: 100%;
        border: unset;
        border-top-left-radius: unset;
        border-bottom-right-radius: unset;
    }
    .contactoWrapper{
        padding: 0;
        height: 100vh;
    }
    .contactoBox{
        width: 90%;
        
        & > div > input,
        & > div > textarea {
            flex: 50%;
        }
    }
    
    .btn__contacto{
        &>span{
            font-size: 2rem;
        }
    }
}