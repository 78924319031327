.autor__main{

    display: flex;
    justify-content: space-around;

    &__agregar{
        border-top: 1px solid black;
        padding-top: 2rem;
        //width: 70%;
        display: flex;
        flex-direction: column;
        & > form {
            margin: 0 30%;
        }
    }
}

.autor__main__agregar-bloque{
    display: flex;
    justify-content: center;

    & > input {
        margin-left: 2rem;
    }
}

.redes_sociales-container{
    //margin: 0 5%;
}