@import './_colors.scss';
$color-gray-dark-3: #999;

$fs-400: 1rem;
$fs-500: 2rem;
$fs-600: 3rem;

:root{
    --fs-slide5: 3vw;

    --fs-titulos: 3.5vw;
    --fs-subtitulos: 1.5vw;
    --fs-agenda: 2vw;

    --fs-greenCard-main: 1.75vw;
    --fs-greenCard-auth: 1.4vw;
    --fs-greenCard-info: 1.2vw;

    --fs-reviewCard-name: 2.5rem;
    --fs-reviewCard-occupation: 1.7rem;
    --fs-reviewCard-review: 1.5rem;

    // --fs-slide3-title: clamp(3rem, 5rem, 6rem);
    // --fs-slide3-info: clamp(1rem, 3rem, 3rem);
    --fs-slide3-title: 5rem;
    --fs-slide3-info: 3rem;
}

.paraTi__slide1{
    display: flex;
    //padding-top: 2rem;
    &__textleft{
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        padding-left: 3%;
        padding-bottom: 10%;
        &-up{
            color: $color_text_green;
            font-size: var(--fs-titulos);
            line-height: var(--fs-titulos);
            //line-height: calc(var(--fs-titulos)-1rem);
        }
        &-mid{
            display: flex;
            flex-direction: column;
            width: 100%;

            color: $color_text_gray;
            font-size: var(--fs-subtitulos);
            line-height: var(--fs-subtitulos);
            //line-height: calc(var(--fs-subtitulos)-1rem);
            margin: 10% 0;
        }
        &-bot{
            background-color: $color_text_green;
            color: white;
            font-size: 2vw;
            font-size: var(--fs-agenda);
            padding: 2vw 2vw;

            border: none;
            cursor: pointer;

            &:hover{
                background-color: lighten($color_text_green, 5%);
            }

            &:focus{

            }
        }
    }

    &__img{
        align-self: center;
        height: 80%;
        padding: 4%;
        & > img {
            width: 100%;
            height: auto;
        }
    }
}

.paraTi__slide2{
    display: flex;

    margin-bottom: 10rem;

    &__textleft{
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 3%;
        padding-right: 10%;
        padding-bottom: 20%;

        &-up{
            color: $color_text_green;
            // font-size: 8rem;
            // line-height: 9rem;
            font-size: var(--fs-titulos);
            line-height: var(--fs-titulos);
            display: flex;
            flex-direction: column;
            margin-bottom: 5rem;
        }

        &-bot{
            color: $color_text_gray;
            // font-size: 4rem;
            // line-height: 5rem;
            font-size: var(--fs-subtitulos);
            line-height: var(--fs-subtitulos);
            display: flex;
            flex-direction: column;
        }
    }

    &__rightCard{
        position: relative;
        padding: 3%;
        &-img{
            & > img {
                width: 100%;
                height: auto;
            }
        }

        &-cardBody{
            background-color: $color_text_green;
            color: white;
            width: 92%;

            position: absolute;
            bottom: 0;
            left: -8%;
            box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
            &-text{
                display: flex;
                flex-direction: column;
                padding: 3% 5%;

                &-main{
                    font-size: var(--fs-greenCard-main);
                }
                &-author{
                    font-size: var(--fs-greenCard-auth);
                    margin-top: 2rem;
                }
                &-info{
                    font-size: var(--fs-greenCard-info);
                }
            }
        }
    }
}

.paraTi__slide3{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: stretch;
    height: 100vh;
    //padding-top: 25vh;
    margin: 20rem 0;
    &__card{
        //height: auto;
        height: 80%;
        flex: 0 0 23%;
        //width: 23%;
        margin-top: 10vh;
        padding: 2rem 0;

        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover{
            box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
            border-radius: 50px;
        }

        &-fig{
            width: 80%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 2rem;
        }

        &-img{
            width: 100%;
            height: auto;
        }

        &-titleGreen{
            color: $color_text_green;
            font-size: var(--fs-slide3-title);
        }
        &-titleBlack{
            color: $color_text_dark;
            font-size: var(--fs-slide3-title);
        }
        &-titleBlue{
            color: $color_text_blue;
            font-size: var(--fs-slide3-title);
        }

        &-info{
            color: hsl(0, 0%, 30%);
            font-size: var(--fs-slide3-info);
            width: 80%;
            margin: auto auto;
        }
    }
}

.paraTi__slide4{
    display: flex;
    flex-direction: column;
    margin: 30rem 0;
    &-title{
        font-size: 4vw;
        color: $color_text_green;
        align-self: center;
        margin-bottom: 5rem;
    }
    &__cards{
        display: flex;
        flex-direction: column;
        gap: 4rem;
        $borderPx: 8px;
        $gapSpan: 0vw;
        &-itemboxBlack{
            background-color: #030027; //030027
            border: $borderPx solid #030027;
            color: white;
            &:hover {
                background-color: white;
                color: black;
                .paraTi__slide4__cards-front{
                    display: none;
                }
                .paraTi__slide4__cards-back{
                    display: flex;
                    flex-direction: column;
                    gap: $gapSpan;
                }
            }
        }
        &-itemboxGreen{
            background-color: $color_text_green;
            border: $borderPx solid $color_text_green;
            color: white;
            &:hover {
                background-color: white;
                color: black;
                .paraTi__slide4__cards-front{
                    display: none;
                }
                .paraTi__slide4__cards-back{
                    display: flex;
                    flex-direction: column;
                    gap: $gapSpan;
                }
            }
        }
        &-itemboxGray{
            background-color: $color-gray-dark-3;
            border: $borderPx solid $color_gray-dark-3;
            color: darken($color_text_gray, 10%);
            color: white;
            &:hover {
                background-color: white;
                color: black;
                .paraTi__slide4__cards-front{
                    display: none;
                }
                .paraTi__slide4__cards-back{
                    display: flex;
                    flex-direction: column;
                    gap: $gapSpan;
                }
            }
        }
        &-itemboxBlue{
            background-color: $color_text_blue;
            border: $borderPx solid $color_text_blue;
            color: white;
            &:hover {
                background-color: white;
                color: black;
                .paraTi__slide4__cards-front{
                    display: none;
                }
                .paraTi__slide4__cards-back{
                    display: flex;
                    flex-direction: column;
                    gap: $gapSpan;
                }
            }
        }

        &-up{
            display: flex;
            justify-content: space-evenly;
        }

        &-down{
            display: flex;
            justify-content: space-evenly;
        }

        &-front{
            font-size: 2vw;
            text-align: center;
        }
        &-back{
            display: none;
            font-size: 1.2vw;
            text-align: center;
        }
    }
}

.itembox{
    width: 20vw;
    height: 20vw;
    padding: 2rem 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all .8s;
    border-top-left-radius: 6vw;
    border-bottom-right-radius: 6vw;
}

.paraTi__slide5{
    margin-top: 10rem;
    margin-bottom: 30rem;

    display: flex;
    flex-direction: column;
    gap: 10rem;

    &__title{
        color: $color_text_blue;
        font-size: var(--fs-slide5);
        width: 100%;
        text-align: center;
    }
    &-cards{
        display: flex;
        flex-direction: column;
        align-items: center;
        & > div  {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            width: 100%;
        }
    }
}

.reviewCard {
    min-height: 40rem;
    max-width: 40rem;
    border: 2px solid hsla(0, 0%, 60%, 0.76);
    padding: 4rem 2rem;
    margin-bottom: 5rem;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    transition: border .2s, box-shadow .2s;

    &-review{
        font-size: var(--fs-reviewCard-review);
        color: $color_text_green;
        padding: 0 3rem;
        flex: 1;
        text-align: center;
        display: flex;
        align-items: center;
    }
    
    &__top{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &-img{
            max-width: 10rem;
            height: auto;
            margin-bottom: 1.5rem;
        }
    
        &-name{
            font-size: var(--fs-reviewCard-name);
        }
    
        &-occupation{
            font-size: var(--fs-reviewCard-occupation);
        }

        &-separator{
            height: 1px;
            width: 80%;
            border-top: 1px solid black;
            margin-top: 1.5rem;
        }
    }

    &:hover{
        border: 2px solid hsl(0, 0%, 60%);
        box-shadow: rgba(17, 17, 26, 0.5) 0px 1px 0px, rgba(17, 17, 26, 0.5) 0px 8px 24px, rgba(17, 17, 26, 0.5) 0px 16px 48px;
    }
}

@media screen and (max-width: 1400px){
    :root{
        --fs-reviewCard-name: 2.5rem;
        --fs-reviewCard-occupation: 1.7rem;
        --fs-reviewCard-review: 1.8rem;
        --fs-slide5: 5vw;
        --fs-slide3-title: 4rem;
        --fs-slide3-info: 2.5rem;
    }

    .paraTi__slide3{
        &__card{
            &-info{
                margin-top: 2rem;
            }
        }
    }
}
@media screen and (max-width: 1200px){
    :root{
        --fs-reviewCard-name: 2.5rem;
        --fs-reviewCard-occupation: 1.7rem;
        --fs-reviewCard-review: 1.8rem;
        --fs-slide5: 5vw;
        --fs-slide3-title: 3.6rem;
        --fs-slide3-info: 2.2rem;
    }
    .paraTi__slide3{
        height: max-content;
        &__card{
            flex: 0 0 28%;
            height: 60%;
            &-info{
                //height: 60%;
            }
        }
    }
}
@media screen and (max-width: 992px){
    :root{
        --fs-slide5: 5vw;
    }
    .paraTi__slide4{
        &__cards {
            gap: 0;
            & > div {
                flex-wrap: wrap;
            }
            &-front{
                font-size: 1.8rem;
            }
            &-back{
                font-size: 1.2rem;
            }
        }
    }
    .paraTi__slide3{
        &__card{
            flex: 0 0 30%;
            height: 65%;
        }
    }

    .itembox{
        width: 20rem;
        height: 20rem;
        margin-bottom: 2rem;
    }

}
@media screen and (max-width: 768px) {

    .paraTi__slide5{
        &-cards > div {
            flex-wrap: wrap;
        }
    }
    .paraTi__slide3{
        height: auto;
        flex-direction: column;
        flex: 1;
        align-items: center;
        gap: 4rem;

        &__card{
            box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
            border-radius: 50px;
            width: 60%;
            margin-top: 0;
        }
    }

    .reviewCard{
    }
}

@media screen and (max-width: 576px) {
    :root{
        --fs-slide5: 3rem;
        --fs-reviewCard-name: 2rem;
        --fs-reviewCard-occupation: 1.5rem;
        --fs-reviewCard-review: 1.3rem;

        --fs-titulos: 10vw;
        --fs-subtitulos: 5vw;
        --fs-agenda: 6vw;
        --fs-greenCard-main: 4vw;
        --fs-greenCard-auth: 4vw;
        --fs-greenCard-info: 2.5vw;
    }

    .paraTi__slide1,
    .paraTi__slide2{
        flex-direction: column;
        &__textleft{
            padding: 0;
            align-items: center;
    
            & > div {
                text-align: center;
            }
        }
    }

    .paraTi__slide1{
        padding: 2rem 2rem;
        align-items: center;

        &__img{
            padding: 5rem 5rem;
        }

        &__textleft-mid{
            margin: 9rem 0;
        }

        &__textleft-bot{
            padding: 3rem;
        }
        
    }

    .paraTi__slide2{
        &__rightCard{

            &-img{
                padding: 0 3rem;
            }

            &-cardBody{
                position: unset;
            }
        }
    }

    .paraTi__slide3{
        height: auto;
        flex-direction: column;
        flex: 1;
        &__card{
            box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
            border-radius: 50px;
            width: 95%;
        }
    }

    .paraTi__slide4{
        &-title{
            font-size: 8vw;
        }
        &__cards{
            &-front{
                font-size: 1.5rem;
            }
            &-back{
                font-size: 1.2rem;
            }
        }
    }
    .itembox{
        border-top-left-radius: 60px;
        border-bottom-right-radius: 60px;
    }

    .reviewCard{
        width: 90%;
    }
}