$color-primary-green: #00BE00;
$color-primary-blue: #0070BA;
$color-primary-gray: #444242;
$color-primary-black: #020023;

$color-gray-light-1: #faf9f9;
$color-gray-light-2: #f4f2f2;
$color-gray-light-3: #f0eeee;
$color-gray-light-4: #ccc;
$color-gray-dark-1: #333;
$color-gray-dark-2: #777;
$color-gray-dark-3: #999;

html {
    font-size: 62.5%;   //1rem = 10px;
}