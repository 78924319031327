@import './colors';

.index__slide__8{
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    width: 100%;
    display: flex;

    &__titulo{
        position: relative;
        height: 100%;
        flex: 0 0 10%;
        &-text{
            font-size: 7.6rem;
            position: absolute;
            transform-origin: top left;
            top: 50%;
            left: 0;
            transform: rotate(270deg) translateX(-50%);
            white-space: nowrap;
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                font-size: 6rem;
            }
            //Pixel 2
            @media screen and (max-width: 411px) {
                font-size: 6rem;
            }
            //iPhone 6/7/8
            @media screen 
            and (max-width: 375px)
            and (height: 667px){
                font-size: 5rem !important;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                font-size: 6rem;
            }
            //Moto G4/Galaxy S5
            @media screen and (max-width: 360px) {
                font-size: 5rem;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                font-size: 5rem;
            }
            @media screen and (max-width: 480px){
                display: flex;
            }
        }
    }

    /*
    &__cards{
        flex: 0 0 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
        @media screen and (max-width: 480px){
            display: flex;
            flex-direction: column;
            position: unset;
        }
        & > div {
            width: 35rem;
            min-height: 25rem;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            @media screen and (max-width: 480px){
                position: inherit;
            }
            // Portrait iPad Pro 12.9"
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                width: 42rem;
            }
            // Portrait iPad Pro 10.5"
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                
            }
            // Portrait iPad
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                width: 25rem;
                min-height: 10rem;
            }
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                width: 20rem;
                min-height: 10rem;
            }
            //Pixel 2
            @media screen and (max-width: 411px) {
                width: 20rem;
                min-height: 10rem;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                
            }
            //Moto G4/Galaxy S5
            @media screen and (max-width: 360px) {
                width: 18rem;
                min-height: 10rem;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                width: 15rem;
                min-height: 10rem;
            }
        }

        &-card1{
            position: absolute;
            top: 18%;
            left: 5%;
            // Portrait iPad Pro 12.9"
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                top: 10%;
                left: 2%;
            }
            // Portrait iPad Pro 10.5"
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                top: 10%;
                left: 5%;
            }
            // Portrait iPad
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                top: 13%;
                left: 5%;
            }
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                margin-top: 18%;
            }
            //Pixel 2
            @media screen and (max-width: 411px) {
                margin-top: 25%;
            }
            //iPhone 6/7/8
            @media screen 
            and (max-width: 375px)
            and (height: 667px){
                margin-top: 20%;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                
            }
            //Moto G4/Galaxy S5
            @media screen and (max-width: 360px) {
                margin-top: 25%;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
            }
            & > div > div > h5 {
                text-align: center;
                color: $color_text_green;
                font-size: 1.8rem;
                //iPhone 6/7/8 plus
                @media screen 
                and (max-width: 414px)
                and (device-height: 736px){
                    font-size: 1.4rem;
                }
                //Pixel 2
                @media screen and (max-width: 411px) {
                    font-size: 1.2rem;
                }
                //iPhone X
                @media screen and (max-width: 375px){
                    font-size: 1.2rem;
                }
                //Moto G4/Galaxy S5
                @media screen and (max-width: 360px) {
                    font-size: 1.2rem;
                }
                //iPhone 5
                @media screen and (max-width: 320px){
                    font-size: 1.2rem;
                }
            }
        }
        &-card2{
            position: absolute;
            top: 38%;
            left: 40%;
            // Portrait iPad Pro 12.9"
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                top: 40%;
                left: 30%;
            }
            // Portrait iPad Pro 10.5"
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                top: 40%;
                left: 30%;
            }
            // Portrait iPad
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                top: 43%;
                left: 32%;
            }
            //iPhone 6/7/8 plus
            @media screen 
            and (max-width: 414px)
            and (device-height: 736px){
                margin: 2rem 0;
            }
            //Pixel 2
            @media screen and (max-width: 411px) {
                margin: 2rem 0;
            }
            //iPhone 6/7/8
            @media screen 
            and (max-width: 375px)
            and (height: 667px){
                margin: 1rem 0 !important;
            }
            //iPhone X
            @media screen and (max-width: 375px){
                margin: 2rem 0;
            }
            //Moto G4/Galaxy S5
            @media screen and (max-width: 360px) {
                margin: 2rem 0;
            }
            //iPhone 5
            @media screen and (max-width: 320px){
                margin: 0.5rem 0;
            }
            & > div > div > h5 {
                text-align: center;
                color: $color_text_blue;
                font-size: 1.8rem;
                //iPhone 6/7/8 plus
                @media screen 
                and (max-width: 414px)
                and (device-height: 736px){
                    font-size: 1.4rem;
                }
                //Pixel 2
                @media screen and (max-width: 411px) {
                    font-size: 1.2rem;
                }
                //iPhone X
                @media screen and (max-width: 375px){
                    
                }
                //Moto G4/Galaxy S5
                @media screen and (max-width: 360px) {
                    font-size: 1.2rem;
                }
                //iPhone 5
                @media screen and (max-width: 320px){
                }
            }
        }
        &-card3{
            position: absolute;
            top: 58%;
            left: 70%;
            //Portrait iPad Pro 12.9"
            @media only screen 
            and (min-device-width: 1024px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                top: 70%;
                left: 50%;
            }
            // Portrait iPad Pro 10.5"
            @media only screen 
            and (min-device-width: 834px) 
            and (max-device-width: 834px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 2) {
                top: 70%;
                left: 52%;
            }
            // Portrait iPad
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 768px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                top: 73%;
                left: 60%;
            }
            & > div > div > h5 {
                text-align: center;
                color: $color_text_dark;
                font-size: 1.8rem;
                //iPhone 6/7/8 plus
                @media screen 
                and (max-width: 414px)
                and (device-height: 736px){
                    font-size: 1.4rem;
                }
                //Pixel 2
                @media screen and (max-width: 411px) {
                    font-size: 1.2rem;
                }
                //iPhone X
                @media screen and (max-width: 375px){
                    font-size: 1.2rem;
                }
                //Moto G4/Galaxy S5
                @media screen and (max-width: 360px) {
                    font-size: 1.2rem;
                }
                //iPhone 5
                @media screen and (max-width: 320px){
                    font-size: 1.2rem;
                }
            }
        }
    }
    */

    &__cards{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 3%;
        //margin: 0 5%;
        margin-left: 5%;
        margin-right: 9%;
        margin-top: 4%;
        & > div {
            width: 35rem;
            //min-height: 25rem;
        }

        &-card1{
            //width: 100%;
            align-self: flex-start;

            & > div > div > h5 {
                text-align: center;
                color: $color_text_green;
                font-size: 1.8rem;
            }
        }

        &-card2{
            align-self: center;
            & > div > div > h5 {
                text-align: center;
                color: $color_text_blue;
                font-size: 1.8rem;
            }
        }

        &-card3{
            align-self: flex-end;
            & > div > div > h5 {
                text-align: center;
                color: $color_text_dark;
                font-size: 1.8rem;
            }
        }
    }

}

.card__image{
    opacity: .85;

    &:hover{
        opacity: 1;
    }
}


//Portrait iPad Pro 12.9"
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){

}
//Portrait iPad Pro 10.5"
@media only screen
and (min-device-width: 834px)
and (max-device-width: 834px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){

}
//Portrait iPad
@media only screen
and (min-device-width: 768px)
and (max-device-width: 768px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){

}

//iPhone 6/7/8 plus
@media screen
and (max-width: 414px)
and (device-height: 736px){

}
//Pixel2
@media screen
and (max-width: 414px)
and (device-height: 736px){

}





@media only screen and (max-width: 768px){
    .index__slide__8{
        &__titulo{
            flex: 0 0 30%;
        }
    }
    .index__slide__8__cards{
        margin-top: 0%;
        & > div {
            align-self: unset;
            width: 45vw;
        }
    }
}